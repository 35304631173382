<template>
  <div class="home">
    <LoadingSpinner :showHide="showHide" />

    <PageHeader heading="Pantheon Advanced Global CDN Toolkit">
      <p>Welcome to the Advanced Global CDN Toolkit. Use the navbar above to find what you need.</p>
    </PageHeader>

  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
  name: 'home',
  components: {
    PageHeader,
    LoadingSpinner
  },
  data() {
    return {
      unicode_yes: String.fromCodePoint(0x2705),
      unicode_no: String.fromCodePoint(0x274c),
      domain_check: {
        url: '',
        test_complete: false,
        test_results: '',
        resp: {},
      },
      loading: false
    }
  },
  computed: {
    showHide: function() {
      if (this.loading) {
        return 'show'
      } else {
        return 'hide'
      }
    }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }
  }
}
</script>
